.g-ranger {
  display: block;
  position: relative;
  height: 2px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 2px;
}
.g-ranger .slider {
  display: block;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  margin-top: -9px;
  margin-left: -9px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #eee;
  cursor: pointer;
}
